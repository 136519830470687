body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --dark: #282c34;
  --dark20: rgb(40, 44, 52, 0.2);
  --light: #61dafb;
  --light30: rgba(97, 218, 251, 0.3);
  --white: #ffffff;
  --green: #4caf50;
  --red: #f17070;
}

* {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
  max-height: -webkit-fill-available;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.contain-longform {
  max-width: 800px;
  margin: 0 auto;
}

h1.plain {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

h1.logo a {
  text-decoration: none;
  color: var(--white);
}