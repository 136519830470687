.App {
  background-color: var(--dark20);
  color: var(--dark);
  font-family: Roboto, Arial, Helvetica, sans-serif;
  min-height: 100vh;
}

.App-wrap {
  padding: 20px 20px;
}

.App-link {
  color: #61dafb;
}

.center {
  text-align: center;
}

input {
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1 {
  font-family: 'Leckerli One', cursive;
}

h5 {
  margin-top: 0;
  font-size: 1.05rem;
}

h6 {
  margin: 0;
  font-size: 0.95rem;
  text-align: center;
}

.columns {
  display: flex;
}

.columns.border {
  border-bottom: 1px solid var(--dark);
}

.columns.border > * {
  border-left: 1px solid var(--dark);
}

.columns.border > *:first-child {
  border-left: none;
}

.columns.c2 > * {
  width: 50%;
}

/* HEADER */

.App-header {
  background-color: var(--dark);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  height: 60px;
  border-bottom: 1px solid var(--light30);
  box-shadow: 0 0 5px 10px var(--light30);
}

.App-header h1 {
  flex: auto;
  width: auto;
  text-align: center;
}

.App-header > span {
  flex: 1;
}

.App-header > span:first-child {
  padding-left: 20px;
}

.App-header h1 span {
  font-size: 0.5em;
}

.App-header .App-link {
  padding-right: 20px;
  text-align: right;
}

/* BODY */

.App-body {
  height: calc(100vh - 120px);
}

.player-list {
  text-align: left;
  width: 100%;
  margin: 0 auto 40px;
}

.player-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.player-list li.space {
  margin-bottom: 10px;
}

.player-list .insert {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--dark);
}

.player-list .insert form {
  width: 100%;
  position: relative;
}

.player-list .insert form input {
  width: 100%;
  padding: 8px 44px 8px 10px;
}

.player-list .insert form .addHandle {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -12px;
}

.currentWildcard {
  margin-top: 10px;
  font-size: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 700;
  /* font-family: 'Leckerli One', cursive; */
  width: 43px;
  height: 60px;
  background-color: #FFF;
  border-radius: 4px;
}

.currentWildcard.colorBlack {
  color: #000;
}

.currentWildcard.colorRed {
  color: #F00;
}

.currentWildcard:before {
  content: attr(data-card);
  position: absolute;
  top: 3px;
  left: 3px;
  font-size: 0.4rem;
}

.currentWildcard:after {
  content: attr(data-card);
  position: absolute;
  bottom: 3px;
  right: 3px;
  font-size: 0.4rem;
  transform: rotate(180deg);
}

.currentDealer {
  margin-bottom: 5px;
  display: block;
}

.scoreInput {
  width: 40px;
  text-align: center;
}

.errorMsg {
  color: var(--red);
  font-weight: 700;
  text-align: center;
  font-size: 12px;
}

.final-results .player-name:after {
  content: ": ";
}

.final-results li {
  margin-bottom: 5px;
}

.final-results li:first-child {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 50px;
}

/* FOOTER */

.App-footer {
  background-color: var(--dark);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  height: 60px;
  border-top: 1px solid var(--light30);
  box-shadow: 0 0 5px 10px var(--light30);
}

.App-footer ul {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.App-footer li {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-footer .player-name {
  font-size: 10px;
}

.App-footer .player-score {
  font-weight: 700;
}